import React, { useState, useEffect, useLayoutEffect } from 'react';

import '../../../../styles/themes/slick-themes.css';
import 'slick-carousel/slick/slick.css';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { COMPONENT_NAME } from './locators';
import { settings } from './config';
import Container from '../../../components/molecules/Container';
import CarouselType2Card from '../../../components/molecules/CarouselCardType2';
import get from 'lodash/get';
import useStyles from './style';
import { useTheme } from '@material-ui/core/styles';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { canUseDOM } from '../../../utils/canUseDOM';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import { LABEL_404_PAGE } from '../../../../constants/index';
import { debounce } from 'lodash';
import { breakpoints } from '../../../../styles/themes/vad/constants';
import { NoSsr } from '@material-ui/core';
import { LANGUAGE_CODE } from '../../../../constants';
import { isLanguageEqual } from '../../../utils/cookie';
import {
  getPageName,
  getComponentName,
} from '../../../utils/getPageComponentInfo';
import { getUId } from '../../../../utils/utility';

const SingleSlideCarousel = props => {
  const templateName = get(props, 'sitecoreContext.route.templateName', '');
  const pageName = getPageName(props);
  const componentName = getComponentName(props);
  const { carouselItems } = props.fields;
  const theme = useTheme();
  const classes = useStyles(props);
  const [is404Page, setIs404Page] = useState(false);
  const [slideHeight, setSlideHeight] = useState();
  const uid = getUId(props)
  const lastComponent = get(props, 'appConfig.lastComponent', '');

  if (carouselItems && carouselItems.length === 1) {
    settings.arrows = false;
  }
  const addAnalyticsInfo = index => {
    const obj = {
      ...analyticsData.pageBodyInteraction.swipeArrowClick,
      ...{
        label: createDynamicLabels([
          get(carouselItems[index], 'fields.title.value', ''),
        ]),
      },
    };
    pushAnaylyticsData(obj);
  };
  useEffect(() => {
    if (canUseDOM && templateName === LABEL_404_PAGE) {
      setIs404Page(true);
    }
  }, [templateName]);

  useEffect(() => {
    calculateSlideHeight();
  }, []);

  useLayoutEffect(() => {
    const calculateSlideHeightWithDebounce = debounce(
      calculateSlideHeight,
      500
    );
    canUseDOM &&
      window.addEventListener('resize', calculateSlideHeightWithDebounce);
    return () =>
      window.removeEventListener('resize', calculateSlideHeightWithDebounce);
  }, []);

  const calculateSlideHeight = () => {
    const screenWidth = canUseDOM && document.body.clientWidth;
    const divHeight =
      screenWidth < breakpoints.values.lg ? '500px' : screenWidth * 0.6;
    setSlideHeight(divHeight);
  };

  // const handleSlideChange = () => {
  //   if (canUseDOM) {
  //     const sliders = document.querySelectorAll(`[data-locators="${COMPONENT_NAME}"]`);
  //     if (sliders.length === 0 || sliders[0].classList.contains('visited')) {
  //       return;
  //     }
  //     sliders && sliders.forEach(sliderDiv => {
  //       sliderDiv.classList.add('visited');
  //       let target = sliderDiv.querySelectorAll('.backImageLarge');
  //       if (target.length > 0) {
  //         target.forEach(item =>
  //           item.style.minHeight = item.clientWidth + 'px'
  //         );
  //       }
  //     });
  //   }
  // }

  settings.rtl = false;
  settings.initialSlide =
    theme && theme.direction === 'rtl'
      ? carouselItems && carouselItems.length - 1
      : 0;
  const isRtl = theme && theme.direction === 'rtl';
  return (
    <Container
      className={`${is404Page ? classes.componentAdjustment : classes.componentWrapper
        } ${classes.root} ${isRtl ? 'rtlClass' : ''} ${lastComponent === componentName ? 'marginBottom-0' : ''
        }`}
      data-locators={COMPONENT_NAME}
      uid={uid}
    >
      {carouselItems && (
        <NoSsr>
          <Slider
            afterChange={index => addAnalyticsInfo(index)}
            //beforeChange={handleSlideChange} //TODO: this line will be ramoved later
            {...settings}
          >
            {carouselItems.map(item => {
              return (
                <CarouselType2Card
                  key={item}
                  is404Page={is404Page}
                  slideHeight={slideHeight}
                  componentName={componentName}
                  pageName={pageName}
                  {...item.fields}
                />
              );
            })}
          </Slider>
        </NoSsr>
      )}
    </Container>
  );
};

SingleSlideCarousel.propTypes = {
  fields: PropTypes.shape({
    carouselItems: PropTypes.array,
  }),
};
export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(SingleSlideCarousel))
);
